<template>
	<div class="mt-lg-4">
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div id="selected_mare">
				<div class="row align-items-center">
					<div class="col">
						<h3>2. {{ $t('pax.les_etalons') }}</h3>
					</div>
					<div class="col-auto">
						<div class="input-group stallion_search">
						    <button class="input-group-text px-3" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
						</div>
					</div>
					<div class="col-auto">
						<div class="input-group filter_button">
							<b-button class="input-group-text" @click="openFiltre">{{ $t('pax.modifier_filtres') }} <font-awesome-icon :icon="['fal', 'filter']" /></b-button>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="paragraphes">{{ $t('pax.text_stallion_infos') }}</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<button 
							v-for="categorie in valid_categories"
							:key="categorie"
							@click="deleteCategorie(categorie)"
							class="btn btn-sm btn-primary rounded-pill mt-1 mr-2 mb-3"
						>
							{{ $t('pax.categories.'+categorie) }}
							<font-awesome-icon :icon="['fal', 'times']" />
						</button>
						<button 
							v-for="monte in valid_monte"
							:key="monte"
							@click="deleteTypeMonte(monte)"
							class="btn btn-sm btn-primary rounded-pill mt-1 mr-2 mb-3"
						>
							{{ $t('pax.type_monte.'+monte) }}
							<font-awesome-icon :icon="['fal', 'times']" />
						</button>
						<button 
							v-for="prix in valid_prix"
							:key="prix"
							@click="deletePrix(prix)"
							class="btn btn-sm btn-primary rounded-pill mt-1 mr-2 mb-3"
						>
							{{ $t('pax.prix_moins_de') }} {{ prix }} €
							<font-awesome-icon :icon="['fal', 'times']" />
						</button>
					</div>
				</div>
				<div class="row stallions-list">
					<div class="col-6" v-for="stallion in stallions_filtred" :key="stallion.horse_id" @click.prevent="chooseStallion(stallion)" :class="{active: stallion_selected_local == stallion, 'col-lg-4' : step_label == 'stallions' || step_label == ''}">
						<div class="stallion">{{ stallion.horse_nom }}</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t('pax.pax_inverse') }}
			</template>

			<div v-html="text_intro"></div>
		</b-modal>

		<b-modal dialog-class="modal-pax modal-filtre" ref="modal-filtre" hide-footer>
			<template v-slot:modal-title>
				{{ $t('pax.filtrer_liste_etalons') }}
			</template>

			<label>{{ $t('pax.categories_etalon') }}</label>
			<div class="checkbox-container row">
				<div class="col-6"
				v-for="categorie in categories"
				:key="categorie.label"
				>
					<b-form-checkbox
						v-model="categorie.value"
						@change="validFiltreNextTick"
					>
						{{ $t('pax.categories.'+categorie.label) }} <font-awesome-icon :icon="['fal', 'times']" />
					</b-form-checkbox>
				</div>
				
			</div>


			<label class="mt-4">{{ $t('pax.prix') }}</label>
			<div class="checkbox-container row">
				<div class="col-6"
					v-for="prix in all_prix"
					:key="prix.label"
				>
					<b-form-checkbox
						v-model="prix.value"
						@change="validFiltreNextTick"
					>
						{{ $t('pax.prix_moins_de') }} {{ prix.label }} € <font-awesome-icon :icon="['fal', 'times']" />
					</b-form-checkbox>
				</div>
			</div>

			<div class="input-group filter_button">
				<b-button class="input-group-text mx-auto mt-4" @click="hideModalFiltre">{{ $t('pax.appliquer_filtre') }} <font-awesome-icon :icon="['fal', 'filter']" /></b-button>
			</div>
		</b-modal>
	</div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import _cloneDeep from 'lodash/cloneDeep';

	export default {
		name: "SelectionStallion",
		props: {
			step_label: {
				type: String,
				default: () => null
			},
			stallion_selected: {
				type: Object,
				default: () => ( null )
			}
		},
		mixins: [Navigation, PaxMixin, Tools],
		data () {
			return {
				addOrEditReady: false,
				stallions: [],
				stallions_filtred: [],
				stallion_selected_local: null,
				showStallionSearch : false,
				categories: [
					{label: 'FRAIS', value: false},
					{label: 'SOLIDARITE', value: false},
					{label: 'DRESSAGE', value: false},
					{label: 'CCE', value: false},
					{label: 'COLLECTION', value: false},
					{label: 'ETALONS CSO OU CCE', value: true}
				],
				type_monte: [
					{label: 'iac', 	value: false},
					{label: 'iart', value: false},
					{label: 'iarp', value: false},
					{label: 'mm', 	value: false},
					{label: 'mn', 	value: false},
					{label: 'iaf', 	value: false},
					{label: 'icsi', value: false},
					{label: 'vp', 	value: false}
				],
				all_prix: [
					{label: 1100, value: false},
					{label: 1500, value: false}
				],
				text_intro: ''
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.text_intro = this.getConfig('translations')['pax_reverse'][Vue.i18n.locale()]
				this.addOrEditReady = false

				const country = this.getConfig('country')
				if(!country) {
					this.$emit("update:step_label", "country")
					return false
				}

				const all_stallions = await this.getStallions()
				this.stallions = all_stallions.filter(stallion => {
					let countries = stallion.horse_pays
					return countries.indexOf(parseInt(country.id)) > -1
				})
				this.stallions_filtred = _cloneDeep(this.stallions)

				this.addOrEditReady = true

				this.valideFiltre()
			},

			async chooseStallion(stallion) {
				this.stallion_selected_local = stallion
				this.$emit("update:stallion_selected", stallion)
			},

			openIntroduction() {
				this.$refs["modal"].show()
			},

			openFiltre() {
				this.$refs["modal-filtre"].show()
			},

			valideFiltre() {
				// this.stallions_filtred = this.stallions.filter(stallion => {
				// 	return (this.valid_categories.length > 0 && stallion.categorie.filter(categorie => this.valid_categories.includes(categorie)).length > 0 || (this.valid_categories.length == 0))
				// 		// && (this.valid_monte.length > 0 && stallion.monte.filter(monte => this.valid_monte.includes(monte)).length > 0 || (this.valid_monte.length == 0))
				// 		&& ((this.valid_prix && stallion.total <= this.valid_prix) || (this.valid_prix.length === 0))
				// })
				this.stallions_filtred = this.stallions.filter(stallion => {
					let has_cat = true
					this.valid_categories.forEach(cat => {
						if(!stallion.categorie.includes(cat)) {
							has_cat = false
						}
					})

					if(this.valid_categories.length == 0 && (stallion.categorie.includes('COLLECTION') || stallion.categorie.includes('DRESSAGE'))) {
						has_cat = false
					}

					return has_cat && ((this.valid_prix.length == 1 && stallion.total <= this.valid_prix[0] && stallion.total !== 0) || (this.valid_prix.length === 0))
				})
			},

			validFiltreNextTick() {
				this.$nextTick(() => {
					this.valideFiltre()
				})
			},

			hideModalFiltre() {
				this.$refs["modal-filtre"].hide()
			},

			deleteCategorie(categorie) {
				this.categories.find(a => a.label == categorie).value = false
				this.valideFiltre()
			},

			deleteTypeMonte(monte) {
				this.type_monte.find(a => a.label == monte).value = false
				this.valideFiltre()
			},

			deletePrix(prix) {
				this.all_prix.find(a => a.label == prix).value = false
				this.valideFiltre()
			},
		},
		computed: {
			valid_categories() {
				return Object.values(this.categories.filter(cat => cat.value)).map(cat => cat.label)
			},
			valid_monte() {
				return Object.values(this.type_monte.filter(monte => monte.value)).map(monte => monte.label)
			},
			valid_prix() {
				return Object.values(this.all_prix.filter(prix => prix.value)).map(prix => prix.label)
			}
		},
		watch: {
			force_refresh() {
				this.init_component()
			},

			step_label(val) {
				if(val == 'stallions_selected') {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
